import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import TableauTaskByVehicule from "../../components/Tableau/TableauTacheVehicule.tsx";
import { tacheHooks } from "../../network/tache";
import Loading from "../../components/Loading";
import { CreateWork } from "../../Modal/CreateWork";
import { workHooks } from "../../network/work";
import TableauWork from "../../components/Tableau/TableauWork";
import { useParams } from "react-router-dom";
import { UpdateWork } from "../../Modal/UpdateWork";


export default function Tasks(props: any) {
  const [showImport, setShowImport] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [donne, setDonne] = useState<{page: number, idVehicule: number}>({
    page: 1,
    idVehicule: props.idVehicule
  });

  const params = useParams()
  let idProjet = params.id || 0
  const {data, isLoading} = workHooks.useGetWorkByProjet(Number(idProjet));

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This task will be deleted permanently.",
      confirmButtonText: "Yes, delete!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };
  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This task will be marked as cancelled.",
      confirmButtonText: "Yes, Cancel!",
      cancelButtonText: `Cancel`,
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: "#fff",
    }).then((result) => {
      if (result.isConfirmed) {
        ///When click Yes
      }
    });
  };

  const nextPage = () => {
    //if (data?.data){
    //  if (donne.page < data.data.countPage) { 
    //    setDonne({...donne, page: donne.page + 1});
    //  } else {
    //    setDonne({...donne, page: donne.page});
    //  }
    //}
  }

  const prevPage = () => {
    if (donne.page > 1) { 
      setDonne({...donne, page: donne.page - 1});
    } else {
      setDonne({...donne, page: donne.page});
    }
  }

  const goToPage = (numPage: number) => {
    setDonne({...donne, page: numPage});
  }

  //useEffect(() => {
  //  if (data?.hasMore) {
  //    tacheHooks.usePrefetchTaskVehicule({page: donne.page, idVehicule: props.idVehicule})
  //  }
  //}, [data, donne.page, props.idVehicule])

  if (isLoading){
    return <Loading />
  }
  return (
    <>
      <div className="card-inner">
        <div className="nk-block mb-2">
          <div className="nk-block-head">
            {
              data == null || Object.keys(data).length === 0 ? (
                <a
                  className="btn btn-primary pull-right"
                  onClick={() => setShowCreate(true)}
                >
                  <em className="icon ni ni-plus" />
                  <span>Créer les travaux a faire</span>
                </a>
              ) : (<></>)
            }
            {
              data != null && Object.keys(data).length !== 0 ? (
                <a
                  className="btn btn-primary pull-right"
                  onClick={() => setShowUpdate(true)}
                >
                  <em className="icon ni ni-plus" />
                  <span>Modifier</span>
                </a>
              ) : (<></>)
            }
            <Dropdown className="pull-right mr-1">
              <Dropdown.Menu>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <a
                      className="fetch-display-click cursor"
                      onClick={() => setShowImport(true)}
                    >
                      <em className="icon ni ni-clipboad-check" />
                      <span>Work Requested</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="select-from-jobcard cursor"
                      onClick={() => setShowSelect(true)}
                    >
                      <em className="icon ni ni-todo" />
                      <span>Approved Jobcard</span>
                    </a>
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>

            <h5 className="title overline-title text-base">Travaux liés au véhicule</h5>
            <p>
              Liste des travaux pour un total de {data?.data.workSteps.length ?? 0}
            </p>
          </div>
        </div>
        {isLoading ? <Loading /> : <TableauWork data={data?.data} nextPage={nextPage} prevPage={prevPage} goToPage={goToPage} />}
      </div>

      
      <CreateWork show={showCreate} onHide={() => setShowCreate(false)} />
      {isLoading ? <Loading /> : (data && data.data ? <UpdateWork dataWork={data?.data} show={showUpdate} onHide={() => setShowUpdate(false)} /> : null)}
    </>
  );
}
